import dynamic from 'next/dynamic';
import type {RiskColor, GetScreenSettings, GetScreenProps, DefaultScreensForRisk, AllScreens} from './types/index';

const DesktopStart = dynamic(() => import('./StartDesktop'));
const MobileStart = dynamic(() => import('./StartMobile'));
const PersonalInfo = dynamic(() => import('./PersonalInfo'));
const PersonalInfoEdit = dynamic(() => import('./PersonalInfoEdit'));
const TimeInfoEdit = dynamic(() => import('./TimeInfoEdit'));
const GuestList = dynamic(() => import('./GuestList'));
const HouseRules = dynamic(() => import('./HouseRules'));
const UsageAgreement = dynamic(() => import('./UsageAgreement'));
const IDCheck = dynamic(() => import('./IDCheck'), {ssr: false});
const IDVerification = IDCheck;
const Selfie = dynamic(() => import('./Selfie'));
const PurposeOfStay = dynamic(() => import('./PurposeOfStay'));
const SecurityDeposit = dynamic(() => import('./SecurityDeposit'));
const BackgroundCheck = dynamic(() => import('./BackgroundCheck'));
const CreditCheck = dynamic(() => import('./CreditCheck'));
const AboutYou = dynamic(() => import('./AboutYou'));
const BuildingScreen = dynamic(() => import('./BuildingScreen'));
const CustomScreen = dynamic(() => import('./CustomScreen'));
const LongTerm = dynamic(() => import('./LongTerm'));
const Overview = dynamic(() => import('./Overview'));
const Finish = dynamic(() => import('./Finish'));
const Coronavirus = dynamic(() => import('./Coronavirus'));
const FileUpload = dynamic(() => import('./FileUpload'));
const AuthorityReporting = dynamic(() => import('./AuthorityReporting'));
const ExtraServices = dynamic(() => import('./ExtraServices'));
const Help = dynamic(() => import('./Help'));

const screens = (isMobile: boolean) => {
    const Start = isMobile ? MobileStart : DesktopStart;
    return {
        names: [
            'Start',
            'PersonalInfo',
            'PersonalInfoEdit',
            'Coronavirus',
            'TimeInfoEdit',
            'GuestList',
            'HouseRules',
            'UsageAgreement',
            'IDVerification',
            'IDCollection',
            'IDCheck',
            'Selfie',
            'FileUpload',
            'PurposeOfStay',
            'SecurityDeposit',
            'BackgroundCheck',
            'CreditCheck',
            'AboutYou',
            'LongTerm',
            'CustomScreen',
            'BuildingScreen',
            'AuthorityReporting',
            'ExtraServices',
            'Overview',
            'Finish',
        ],
        components: {
            Start: Start,
            PersonalInfo: PersonalInfo,
            PersonalInfoEdit: PersonalInfoEdit,
            TimeInfoEdit: TimeInfoEdit,
            GuestList: GuestList,
            HouseRules: HouseRules,
            UsageAgreement: UsageAgreement,
            IDVerification: IDVerification,
            IDCollection: IDCheck,
            IDCheck: IDCheck,
            Selfie: Selfie,
            FileUpload: FileUpload,
            PurposeOfStay: PurposeOfStay,
            SecurityDeposit: SecurityDeposit,
            BackgroundCheck: BackgroundCheck,
            CreditCheck: CreditCheck,
            AboutYou: AboutYou,
            LongTerm: LongTerm,
            CustomScreen: CustomScreen,
            BuildingScreen: BuildingScreen,
            Coronavirus: Coronavirus,
            AuthorityReporting: AuthorityReporting,
            ExtraServices: ExtraServices,
            Overview: Overview,
            Finish: Finish,
            Help: Help,
        },
    };
};

const getScreen = (
    riskColor: RiskColor,
    isMobile: boolean,
    page: number,
    settings: GetScreenSettings,
    props: GetScreenProps,
) => {
    const {country, reservation, screenName} = props;
    const defaultScreensForRisk: DefaultScreensForRisk = {
        green: ['Start', 'PersonalInfo', 'HouseRules', 'Overview', 'Finish'],
        yellow: ['Start', 'PersonalInfo', 'HouseRules', 'PurposeOfStay', 'AboutYou', 'Overview', 'Finish'],
        orange: [
            'Start',
            'PersonalInfo',
            'HouseRules',
            'UsageAgreement',
            'IDVerification',
            'PurposeOfStay',
            'AboutYou',
            'Overview',
            'Finish',
        ],
        red: [
            'Start',
            'PersonalInfo',
            'GuestList',
            'HouseRules',
            'UsageAgreement',
            'IDVerification',
            'PurposeOfStay',
            'AboutYou',
            'Overview',
            'Finish',
        ],
    };

    const allScreens: AllScreens = screens(isMobile);
    let screenStack: string[] = [];
    if (!riskColor) {
        riskColor = 'red';
    }
    if (riskColor === 'black') {
        defaultScreensForRisk.black = [...defaultScreensForRisk.red];
    }

    if (settings) {
        const screensSettings = settings.guest_portal_screens_settings || {};
        const idVerificationSettings = screensSettings.IDVerification || {};
        const longTermSettings = screensSettings.LongTerm || {};
        const coronavirusSettings = screensSettings.Coronavirus || {};
        const backgroundSettings = screensSettings.BackgroundCheck || {};
        const creditSettings = screensSettings.CreditCheck || {};
        const authreportSettings = screensSettings.AuthorityReporting || {};
        const buildingScreen = reservation?.building_screen || null;

        const usaOnly = Boolean(idVerificationSettings.usa_only) && country && country.toLowerCase() !== 'us';
        const unverifiedOnly = Boolean(idVerificationSettings.unverified_only) && reservation?.guest_preverified;
        const withoutListing = reservation?.without_listing;

        // remove ID verification if disabled, set for USA-only or guest is already verified by OTA
        if (!settings.enable_guest_portal_id_verification || usaOnly || (unverifiedOnly && !screenName)) {
            Object.keys(defaultScreensForRisk).forEach((color) => {
                defaultScreensForRisk[color] = defaultScreensForRisk[color]?.filter(
                    (name) => name !== 'IDVerification',
                );
            });
            allScreens.names = allScreens.names.filter((screenName) => screenName !== 'IDVerification');
            delete allScreens.components['IDVerification'];
            allScreens.components['IDVerification'] = null;
        }
        if (!settings.enable_guest_portal_id_collection || usaOnly || (unverifiedOnly && !screenName)) {
            Object.keys(defaultScreensForRisk).forEach((color) => {
                defaultScreensForRisk[color] = defaultScreensForRisk[color].filter((name) => name !== 'IDCollection');
            });
            allScreens.names = allScreens.names.filter((screenName) => screenName !== 'IDCollection');
            delete allScreens.components['IDCollection'];
            allScreens.components['IDCollection'] = null;
        }

        // remove security deposit if disabled
        if (!settings.enable_guest_portal_deposit) {
            Object.keys(defaultScreensForRisk).forEach((color) => {
                defaultScreensForRisk[color] = defaultScreensForRisk[color].filter(
                    (name) => name !== 'SecurityDeposit',
                );
            });
            allScreens.names = allScreens.names.filter((screenName) => screenName !== 'SecurityDeposit');
            delete allScreens.components['SecurityDeposit'];
            allScreens.components['SecurityDeposit'] = null;
        }

        // remove background check if disabled
        if (
            !settings.enable_guest_portal_background_check ||
            (backgroundSettings.nights &&
                Number(reservation?.nights) < Number(backgroundSettings.nights) &&
                !screenName)
        ) {
            Object.keys(defaultScreensForRisk).forEach((color) => {
                defaultScreensForRisk[color] = defaultScreensForRisk[color].filter(
                    (name) => name !== 'BackgroundCheck',
                );
            });
            allScreens.names = allScreens.names.filter((screenName) => screenName !== 'BackgroundCheck');
            delete allScreens.components['BackgroundCheck'];
            allScreens.components['BackgroundCheck'] = null;
        }

        // remove credit check if disabled
        if (
            !settings.enable_guest_portal_credit_check ||
            !reservation?.nights ||
            (creditSettings.nights && Number(reservation.nights) < Number(creditSettings.nights) && !screenName)
        ) {
            Object.keys(defaultScreensForRisk).forEach((color) => {
                defaultScreensForRisk[color] = defaultScreensForRisk[color].filter((name) => name !== 'CreditCheck');
            });
            allScreens.names = allScreens.names.filter((screenName) => screenName !== 'CreditCheck');
            delete allScreens.components['CreditCheck'];
            allScreens.components['CreditCheck'] = null;
        }

        // remove LongTerm from list if below threshold
        if (
            !reservation?.nights ||
            (longTermSettings.nights && Number(reservation.nights) < Number(longTermSettings.nights) && !screenName)
        ) {
            allScreens.names = allScreens.names.filter((screenName) => screenName !== 'LongTerm');
            delete allScreens.components['LongTerm'];
            allScreens.components['LongTerm'] = null;
        }

        // remove Coronavirus from list, if needed, when using automatic detection
        if (coronavirusSettings.detectedOnly && !reservation?.travel_advisory && !screenName) {
            allScreens.names = allScreens.names.filter((screenName) => screenName !== 'Coronavirus');
            delete allScreens.components['Coronavirus'];
            allScreens.components['Coronavirus'] = null;
        }

        // remove BuildingScreen from list
        if (!buildingScreen || !buildingScreen.enabled) {
            allScreens.names = allScreens.names.filter((screenName) => screenName !== 'BuildingScreen');
            delete allScreens.components['BuildingScreen'];
            allScreens.components['BuildingScreen'] = null;
        }

        // remove AuthorityReporting from list
        const region = (authreportSettings.regions || []).find((r) => r.country === reservation?.listing_country_code);
        if (!region) {
            allScreens.names = allScreens.names.filter((screenName) => screenName !== 'AuthorityReporting');
            delete allScreens.components['AuthorityReporting'];
            allScreens.components['AuthorityReporting'] = null;
        }

        // remove listing-related screens if reservation does not have a listing
        if (withoutListing) {
            [
                'Coronavirus',
                'PersonalInfo',
                'PersonalInfoEdit',
                'TimeInfoEdit',
                'GuestList',
                'HouseRules',
                'UsageAgreement',
                'PurposeOfStay',
                'LongTerm',
                'BuildingScreen',
                'Overview',
            ].forEach((name) => delete allScreens.components[name]);
        }

        // add screens user selected for current risk color
        if (settings.guest_portal_screens) {
            allScreens.names.forEach((screenName) => {
                if (
                    allScreens.components[screenName] &&
                    settings.guest_portal_screens?.[screenName as keyof typeof settings.guest_portal_screens] &&
                    settings.guest_portal_screens?.[screenName as keyof typeof settings.guest_portal_screens][riskColor]
                ) {
                    screenStack.push(screenName);
                }
            });
        }
    }

    // load Single Screen requested by name (/edit/:screen/:id)
    if (screenName && allScreens.components[screenName]) {
        screenStack = [screenName, 'Finish'];
    }

    // use default screens if user didn't specify
    if (!settings || screenStack.length === 0) {
        defaultScreensForRisk[riskColor].forEach((screenName) => {
            screenStack.push(screenName);
        });
    }

    // change 'IDVerification' with 'IDCheck' screen if we have valid API keys for module
    allScreens.components.IDVerification = IDCheck;
    screenStack.forEach((screen, index) => {
        if (screen === 'IDVerification') {
            screenStack[index] = 'IDCheck';
        }
    });

    // add meta data
    const isFinalPage = !!!screenStack[page + 2];
    const screenCount = screenStack.length;

    // return screen
    return {
        CurrentScreen: allScreens.components[screenStack[page]],
        isFinalPage,
        screenCount,
        screenStack,
        screenName: screenStack[page],
    };
};

export {getScreen, screens};
export default getScreen;
