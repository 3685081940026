/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import clsx from 'clsx';
import {withRouter} from 'next/router';
import NextLink from 'next/link';
import MuiLink from '@material-ui/core/Link';
import type {NextComposedProps, LinkProps} from './types/Link';
import type {NextComponentType, NextPageContext} from 'next';

const NextComposed = React.forwardRef<HTMLAnchorElement, NextComposedProps>(function NextComposed(props, ref) {
    const {as, href, prefetch, ...other} = props;

    return (
        <NextLink href={href} prefetch={prefetch} as={as}>
            <a ref={ref} {...other} />
        </NextLink>
    );
});

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/#with-link
const Link: NextComponentType<NextPageContext, any, LinkProps> = (props) => {
    const {activeClassName = 'active', router, className: classNameProps, innerRef, naked, ...other} = props;

    const className = clsx(classNameProps, {
        [activeClassName]: router.pathname === props.href && activeClassName,
    });

    if (naked) {
        return <NextComposed className={className} ref={innerRef} {...other} />;
    }

    return <MuiLink component={NextComposed} className={className} ref={innerRef} {...other} />;
};

const RouterLink = withRouter(Link);

export default React.forwardRef<HTMLAnchorElement, any>((props, ref: any) => <RouterLink {...props} innerRef={ref} />); // TODO Fix type for innerRef
