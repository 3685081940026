export const expressRequestIP = (req, params = {}) => {
    params = params || {};
    const {priority, fallback} = params;
    if (!req || !req.headers) {
        return null;
    }
    const ipList = [priority, req.headers['x-forwarded-for'], req.ip, fallback];
    const ip = ipList.filter((ip) => typeof ip === 'string').find((ip) => ip && ip !== '');
    if (!ip) {
        return null;
    }
    return ip.split(',').map((i) => i.trim())[0];
};
