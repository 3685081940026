import Typography from '@material-ui/core/Typography';
import React from 'react';
import type {ErrorInfo} from 'react';
import config from '../config';
import {Content} from './Styles';
import type {ErrorBoundaryProps, ErrorBoundaryState} from './types/ErrorBoundary';

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {error: null, errorInfo: null};
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // Catch errors in any components below and re-render with error message
        const {pageProps} = this.props;
        const {reservationId} = pageProps || {};
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
        console.error(
            `Exception caught in Guest Portal error boundary component for reservation ${reservationId}: ${error}`,
        );
        // return super.componentDidCatch(error, errorInfo)
    }

    render() {
        const isDev = !config.stage || !config.stage.includes('prod');
        if (this.state.errorInfo) {
            let title = this.state.error && this.state.error.toString();
            let subtitle = this.state.errorInfo.componentStack;
            if (!isDev) {
                title = 'Oops';
                subtitle =
                    'This component has stopped working. Our technical team has been notified. Please try again later.';
            }
            return (
                <Content align="left">
                    <Typography variant="h4" color="textSecondary" gutterBottom>
                        {title}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                        {subtitle}
                    </Typography>
                </Content>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
