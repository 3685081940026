import React, {Fragment} from 'react';
import Typography from '@material-ui/core/Typography';
import {Spacing} from '../../../components/Styles';
import Link from '../../../components/Link';
import {getOrNull} from '../../../libs/utils';
import type {TranslationProp, SecurityDepositHelpProps, AuthorityReportingHelpProps, Region} from './types/help';

const PersonalInfo: React.FC<TranslationProp> = ({t}) => {
    return (
        <Fragment>
            <Typography variant="h2" gutterBottom>
                {t('title')}
            </Typography>
            <Spacing />
            <Typography variant="h4" color="textSecondary" gutterBottom>
                {t('subtitle')}
            </Typography>
            <Typography variant="body1" gutterBottom>
                {t('body1')}
            </Typography>
        </Fragment>
    );
};

const GuestList: React.FC<TranslationProp> = ({t}) => {
    return (
        <Fragment>
            <Typography variant="h2" gutterBottom>
                {t('title')}
            </Typography>
            <Spacing />
            <Typography variant="h4" color="textSecondary" gutterBottom>
                {t('subtitle')}
            </Typography>
            <Typography variant="body1" gutterBottom>
                {t('body1')}
            </Typography>
        </Fragment>
    );
};

const HouseRules: React.FC<TranslationProp> = ({t}) => {
    return (
        <Fragment>
            <Typography variant="h2" gutterBottom>
                {t('title')}
            </Typography>
            <Spacing />
            <Typography variant="h4" color="textSecondary" gutterBottom>
                {t('subtitle')}
            </Typography>
            <Typography variant="body1" gutterBottom>
                {t('body1')}
            </Typography>
        </Fragment>
    );
};

const UsageAgreement: React.FC<TranslationProp> = ({t}) => {
    return (
        <Fragment>
            <Typography variant="h2" gutterBottom>
                {t('title')}
            </Typography>
            <Spacing />
            <Typography variant="h4" color="textSecondary" gutterBottom>
                {t('subtitle')}
            </Typography>
            <Typography variant="body1" gutterBottom>
                {t('body1')}
            </Typography>
        </Fragment>
    );
};

const IDCheck: React.FC<TranslationProp> = ({t}) => {
    return (
        <Fragment>
            <Typography variant="h2" gutterBottom>
                {t('title')}
            </Typography>
            <Spacing />
            <Typography variant="h4" color="textSecondary" gutterBottom>
                {t('subtitle')}
            </Typography>
            <Typography variant="body1" gutterBottom>
                {t('body1')}
            </Typography>
            <Typography variant="body1" gutterBottom>
                {t('body2')}
            </Typography>
        </Fragment>
    );
};

const PurposeOfStay: React.FC<TranslationProp> = ({t}) => {
    return (
        <Fragment>
            <Typography variant="h2" gutterBottom>
                {t('title')}
            </Typography>
            <Spacing />
            <Typography variant="h4" color="textSecondary" gutterBottom>
                {t('subtitle')}
            </Typography>
            <Typography variant="body1" gutterBottom>
                {t('body1')}
            </Typography>
        </Fragment>
    );
};

const SecurityDeposit: React.FC<SecurityDepositHelpProps> = ({t, source}) => {
    const airbnb = /airbnb/i.test(source || '');
    return (
        <Fragment>
            <Typography variant="h2" gutterBottom>
                {t('title')}
            </Typography>
            <Spacing />
            <Typography variant="h4" color="textSecondary" gutterBottom>
                {t('subtitle')}
            </Typography>
            {!airbnb && (
                <Fragment>
                    <Typography variant="body1" gutterBottom>
                        {t('body1')}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {t('body2')}
                    </Typography>
                </Fragment>
            )}
            {airbnb && (
                <Typography variant="body1" gutterBottom>
                    {t('airbnb')}
                </Typography>
            )}
            <Spacing />
        </Fragment>
    );
};

const AboutYou: React.FC<TranslationProp> = ({t}) => {
    return (
        <Fragment>
            <Typography variant="h2" gutterBottom>
                {t('title')}
            </Typography>
            <Spacing />
            <Typography variant="h4" color="textSecondary" gutterBottom>
                {t('subtitle')}
            </Typography>
            <Typography variant="body1" gutterBottom>
                {t('body1')}
            </Typography>
        </Fragment>
    );
};

const AuthorityReporting: React.FC<AuthorityReportingHelpProps> = ({
    t,
    listing_country_code,
    listing_country,
    settings,
}) => {
    const screenSettings = getOrNull('guest_portal_screens_settings.AuthorityReporting', settings) || {};
    const region: Region = (screenSettings.regions || []).find((r: Region) => r.country === listing_country_code) || {};
    const links = {
        ES: 'http://www.interior.gob.es/web/servicios-al-ciudadano/normativa/leyes-organicas/ley-organica-4/2015-de-30-de-marzo#CAP%C3%8DTULO%20V',
        CZ: 'https://www.policie.cz/clanek/ubytovani-dokumenty-102.aspx',
    };
    return (
        <Fragment>
            <Typography variant="h2" gutterBottom>
                {t('title')}
            </Typography>
            <Spacing />
            <Typography variant="h4" color="textSecondary" gutterBottom>
                {t('subtitle')}
            </Typography>
            <Typography variant="body1" gutterBottom>
                {t(
                    listing_country_code && links.hasOwnProperty(listing_country_code || '')
                        ? listing_country_code
                        : 'body1',
                    {
                        age: region.age,
                        country: listing_country,
                        strong: (children: React.ReactChild) => <strong>{children}</strong>,
                        span: (children: React.ReactChild) => <span>{children}</span>,
                        link: (children: React.ReactChild) => (
                            <Link
                                href={
                                    listing_country_code && links.hasOwnProperty(listing_country_code)
                                        ? links[listing_country_code as keyof typeof links]
                                        : '#'
                                }
                                target="_blank"
                            >
                                {children}
                            </Link>
                        ),
                    },
                )}
            </Typography>
        </Fragment>
    );
};

const Overview: React.FC<TranslationProp> = ({t}) => {
    return (
        <Fragment>
            <Typography variant="h2" gutterBottom>
                {t('title')}
            </Typography>
            <Spacing />
            <Typography variant="body1" gutterBottom>
                {t('body1')}
            </Typography>
        </Fragment>
    );
};

export {
    PersonalInfo,
    GuestList,
    HouseRules,
    UsageAgreement,
    IDCheck,
    PurposeOfStay,
    SecurityDeposit,
    AuthorityReporting,
    AboutYou,
    Overview,
};
