import apiClient from '../../libs/apiClient';
import {isHexColor} from '../../libs/utils';
import {getDevice} from '../../components/hooks/useDeviceDetect';
import type {RouterQuery, Props} from './types/utils';

export const getReservation = async (id: string) => {
    try {
        return await apiClient({path: `/guestportal/reservation/${id}`, method: 'GET'});
    } catch (e) {
        throw (e.response && e.response.data) || e;
    }
};
export const submitForm = async (reservationId: string, data: {}) => {
    try {
        return await apiClient({path: `/guestportal/finished/${reservationId}`, method: 'POST', data});
    } catch (e) {
        throw (e.response && e.response.data) || e;
    }
};

export const getContextProps = (routerQuery: RouterQuery, width: string) => {
    const isEmbedded = Boolean(routerQuery.embed);
    const props: Props = {
        isEmbedded,
        isKiosk: Boolean(routerQuery.kiosk),
    };
    const mobileSizes = ['xs', 'sm', 'md'];
    // if (isEmbedded) {
    //     mobileSizes.splice(mobileSizes.indexOf('md'), 1);
    // }
    props.isMobile = mobileSizes.includes(width);
    const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
    if (userAgent !== 'SSR') {
        const currentDevice = getDevice(userAgent);
        props.isMobile = currentDevice?.isMobile?.();
    }

    if (isEmbedded) {
        if (routerQuery.hide) {
            const elements = routerQuery.hide.split(',').map((e) => e.trim());
            if (elements.includes('logo')) {
                props.hideLogo = true;
            }
            if (elements.includes('topbar')) {
                props.hideTopbar = true;
            }
            if (elements.includes('image')) {
                props.hideImage = true;
            }
        }
        if (routerQuery.useScroll) {
            props.useScroll = true;
        }
        if (routerQuery.paintBackground) {
            props.paintBackground = [true, 'true', 'yes', 'y', '1', 1].includes(routerQuery.paintBackground);
        }
        if (routerQuery.isMobile) {
            props.isMobile = [true, 'true', 'yes', 'y', '1', 1].includes(routerQuery.isMobile);
        }
        if (routerQuery.color && isHexColor(routerQuery.color)) {
            props.color = `#${routerQuery.color}`.replace('##', '#');
        }
        if (routerQuery.maxWidth && ['xs', 'sm', 'md', 'lg', 'xl'].includes(routerQuery.maxWidth)) {
            props.maxWidth = routerQuery.maxWidth;
        }
    }
    return props;
};
