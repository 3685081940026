import { useEffect } from 'react'

type onError = () => void
type onSuccess = () => void

export const getDevice = (userAgent: NavigatorID['userAgent']) => {
    if (!userAgent) {
        return {}
    }

    const isAndroid = () => Boolean(userAgent.match(/Android/i))
    const isIos = () => Boolean(userAgent.match(/iPhone|iPad|iPod/i))
    const isOpera = () => Boolean(userAgent.match(/Opera Mini/i))
    const isWindows = () => Boolean(userAgent.match(/IEMobile/i))
    const isChromeOnIos = () => Boolean(userAgent.match(/CriOS/i))
    const isSSR = () => Boolean(userAgent.match(/SSR/i))
    const isMobile = () => Boolean(isAndroid() || isIos() || isOpera() || isWindows() || isChromeOnIos())
    const isDesktop = () => Boolean(!isMobile() && !isSSR())
    const isMediaCapable = async (onError?: onError, onSuccess?: onSuccess) => {
        if (typeof navigator === 'undefined' || !navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            return false
        }
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true })
            if (stream) {
                const tracks = stream.getTracks()
                tracks.forEach((track) => track.stop())
                if (onSuccess) {
                    return onSuccess()
                }
                else {
                    return true
                }
            }
        } catch (e) {
            console.error(e)
            if (onError) {
                return onError()
            } else {
                return false
            }
        }
    }
    return {
        isMobile,
        isDesktop,
        isAndroid,
        isChromeOnIos,
        isIos,
        isSSR,
        isMediaCapable,
        userAgent
    }
}
const useDeviceDetect = () => {
    useEffect(() => {
    }, [])
    const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent
    return getDevice(userAgent)
}

export default useDeviceDetect