import type {LogErrorProps} from './types/rollbar.types';

const logError = (error: Error, _level = 'info', props: LogErrorProps = {}) => {
    const {reservation, formValues} = props;
    let data = {
        message: '',
        context: {
            reservation_id: reservation?.id,
            name: formValues?.full_name,
            color: reservation?.protect_color,
            host: reservation?.company ? reservation.company.name : reservation?.host_name,
        },
    };
    if (typeof error === 'string') {
        data.message = error;
    } else {
        data = {
            ...data,
            ...error,
        };
    }
    console.error(`Error caught for reservation ${reservation?.id}: ${JSON.stringify(data)}`);
};

export {logError};
export default logError;
